/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import Image from 'Component/Image';
import ProductPrice from 'Component/ProductPrice';
import TextPlaceholder from 'Component/TextPlaceholder';
import TierPrices from 'Component/TierPrices';
import { ProductType } from 'Type/ProductList.type';
import DeliveryIcon from 'Component/Deliveryicon/DeliveryIcon.component';
import Popup from 'Component/Popup';
import {
    getMaxQuantity, getMinQuantity, getPrice, getProductInStock, getThumbnailImage, getBaseImage
} from 'Util/Product/Extract';
import { Tooltip } from 'react-tooltip';

import {
    GroupedProductsItem as SourceGroupedProductsItem
} from 'SourceComponent/GroupedProductsItem/GroupedProductsItem.component';

import AddToCart from 'Component/AddToCart';

import './GroupedProductsItem.style';

/**
 * Grouped Product Item
 * @class GroupedProduct
 * @namespace Scandipwa/Component/GroupedProductsItem/Component
 */
export class GroupedProductsItemComponent extends SourceGroupedProductsItem {
    static propTypes = {
        product: ProductType.isRequired,
        setQuantity: PropTypes.func.isRequired,
        itemCount: PropTypes.number.isRequired,
        showImages: PropTypes.bool,
        addToCart: PropTypes.func,
        onQuantityChange: PropTypes.func
    };

    renderTitle() {
        const {
            product: {
                name,
                price_range: priceRange,
                type_id: type,
                dynamic_price: dynamicPrice,
                attributes
            },
            attributeMetadata
        } = this.props;
        const norm_attributes = Array.isArray(attributes) ? attributes : Object.values(attributes);

        const packagingUnitAttrOptions = (attributeMetadata.find((pattr) => pattr.attribute_code == 'packaging_unit') ?? {}).attribute_options ?? [];
        const packagingUnitValue = (norm_attributes.find((attr) => attr.attribute_code == 'packaging_unit') ?? {}).attribute_value;
        const packagUnitValue = (norm_attributes.find((attr) => attr.attribute_code == 'packing_unit') ?? {}).attribute_value;
        const productName = packagingUnitValue ? (packagingUnitAttrOptions.find((puo) => puo.value == packagingUnitValue) ?? { label: null }).label : name;

        return (
            <div block="GroupedProductsItem" elem="Title">
                <div block="GroupedProductsItem" elem="TitleTopWrapper">
                    <p>
                        <TextPlaceholder content={ productName ? `${productName}${packagUnitValue > 1 ? ` (${packagUnitValue} ${__('pcs')})` : ''}` : null } />
                    </p>
                    <ProductPrice
                    price={ getPrice(priceRange, dynamicPrice, {}, type) }
                    mods={ { type: 'regular' } }
                    />
                </div>
                { this.renderPricePerUnit() }
            </div>
        );
    }

    renderPricePerUnit () {
        const {
            product: {
                name,
                price_range: priceRange,
                type_id: type,
                dynamic_price: dynamicPrice,
                attributes = {}
            }
        } = this.props;

        const norm_attributes = Array.isArray(attributes) ? attributes : Object.values(attributes);
        const showPricePerUnit = norm_attributes.some(({attribute_code, attribute_value}) => attribute_code === "show_price_per_unit" && attribute_value === "1");
        const hasPackingUnit = norm_attributes.some(({attribute_code, attribute_value}) => attribute_code === "packing_unit" && attribute_value !== null);
        
        if(!showPricePerUnit) {
            return null;
        }

        if(!hasPackingUnit) {
            return null;
        }

        const priceObj = getPrice(priceRange, dynamicPrice, {}, type);
        const finalPriceValue = priceObj.price.finalPrice.value;
        const packingUnit = attributes.find(({attribute_code}) => attribute_code === "packing_unit" );

        const pricePerPiece = (finalPriceValue / packingUnit.attribute_value);
        const formatedPrice = Math.round(pricePerPiece * 100) / 100;

        if (packingUnit.attribute_value <= 1) {
            return null;
        }

        return (
            <span block="GroupedProductItem" elem="PricePerUnit">
                {priceObj.price.finalPrice.currency + " " + pricePerPiece.toFixed(2) + " " + __('per piece')}
            </span>
        )

    }

    renderTierPrices() {
        const { product } = this.props;

        return <TierPrices product={ product } />;
    }

    getError(value) {
        const {
            product = {}
        } = this.props;

        const valueNum = +value;

        if (!!+value && !getProductInStock(product)) {
            return __('Product is out of stock');
        }

        const min = getMinQuantity(product);

        if (valueNum !== 0 && valueNum < min) {
            return __('Minimal value is %s!', min);
        }

        return true;
    }

    renderQuantity() {
        const {
            product: {
                stock_item: {
                    in_stock
                },
                attributes: {
                    attribute_code
                },
                attributes
            },
            product: { id } = {},
            product,
            setQuantity,
            itemCount = 0,
            quantity
        } = this.props;

        const {
            colorCode,
            stockStatusLabel
        } = this.getStockInfo(attributes, in_stock);

        // Might have to use getProductInStock for disabling button, but for now used antons logic
        // if (!getProductInStock(product)) {
        //     return (
        //         <div block="GroupedProductsItem" elem="OutOfStock">
        //             { __('Out of stock') }
        //         </div>
        //     );
        // }

        const max = getMaxQuantity(product);

        const options = [];
        obj = {}
    
        for(var i = 1; i < 51; i++) {
            // for(var i = 0; i < max; i++) {
          var obj = {};
    
          obj['value'] = i;
          obj['label'] = i;
          options.push(obj);
        }

        return (
            <div block="GroupedProductsItem" elem="QuantityAddToCart">
                { this.renderStockStatus() }
                <Field
                type={ FIELD_TYPE.select }
                attr={ {
                    id: `item_qty_${id}`,
                    name: `item_qty_${id}`,
                    // value: `${sortDirection} ${sortKey}`,
                    noPlaceholder: true
                } }
                events={ {
                    onChange: this.props.onQuantityChange
                } }
                isSortSelect={false}
                options={ options }
                mix={ { block: 'GroupedProductsItem', elem: 'Select' } }
                />
                <AddToCart product={this.props.product} quantity={quantity} isDisabled={colorCode === "red"}/>
            </div>
        );
    }

    getStockInfo (attributes, inStock) {
        const norm_attributes = Array.isArray(attributes) ? attributes : Object.values(attributes);
        const isUnavailable = norm_attributes.some(
            ({attribute_code, attribute_value}) => attribute_code === "unavailable" && attribute_value == "1"
        );

        if (isUnavailable) {

            return {
                colorCode: 'red',
                stockStatusLabel: __('Not Available')
            }

        } else {
            if (inStock) {
                return {
                    colorCode: 'green',
                    stockStatusLabel: __('Delivery time: 2 Days')
                }
            } else {
                return {
                    colorCode: 'yellow',
                    stockStatusLabel: __('Delivery time: 3 - 4 Days')
                }
            }
        }

    }

    renderStockStatus() {
        const {
            product: {
                id,
                stock_item: {
                    in_stock
                },
                attributes: {
                    attribute_code
                },
                attributes
            }
        } = this.props;
        const {
            colorCode,
            stockStatusLabel
        } = this.getStockInfo(attributes, in_stock);

        return (
            <div block="GroupedProductsItem" elem="DeliveryIcon">
                
                <span id={"StockStatusToolTip_" + id} block="GroupedProductsItem" elem="DeliveryIcon" mods={{[colorCode]: true}}>
                    <DeliveryIcon />
                </span>

                <Tooltip
                    anchorId={"StockStatusToolTip_" + id}
                    place="top"
                    variant="info"
                    content={stockStatusLabel}
                    classNameArrow="arrowBottom"
                />
            </div>
            
        );

    }

    renderImage() {
        const { product, showImages = true, popupId, showPopup } = this.props;
        const imageUrl = getThumbnailImage(product);
        const baseImageUrl = getBaseImage(product);

        if(showImages === false) {
            return null;
        }

        return (
            <>
                <img
                  block='GroupedProductsItem'
                  elem='Image'
                  src={ imageUrl }
                  alt="Product Thumbnail"
                  onClick={() => showPopup(popupId, {})}
                  style={{visibility: imageUrl ? 'visible' : 'hidden'}}
                />
                <Popup
                  id={ popupId }
                //   clickOutside={ false }
                  mix={ { block: 'GroupedProductsItem', elem: 'ImagePopup'} }
                >
                    <img
                      block='GroupedProductsItem'
                      elem='ImageBig'
                      src={ baseImageUrl }
                      alt="Product Thumbnail"
                    />
                </Popup>
            </>
        );
    }

    render() {
        const { showImages = true } = this.props;

        return (
            <li block="GroupedProductsItem" aria-label="Product Item">
                { this.renderImage(showImages) }
                { this.renderTitle() }
                { this.renderQuantity() }
                { this.renderTierPrices() }
            </li>
        );
    }
}

export default GroupedProductsItemComponent;
