/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import Loader from 'Component/Loader';
import { LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { ProductLabelsComponent } from 'Component/ProductLabels/ProductLabels.component';
import GroupedProductList from 'Component/GroupedProductList';
import GroupedProductsItem from 'Component/GroupedProductsItem';
import ChevronIcon from '@scandipwa/scandipwa/src/component/ChevronIcon';
import { RIGHT } from 'Component/ChevronIcon/ChevronIcon.config';
import TextPlaceholder from 'Component/TextPlaceholder';

import {
    ProductCard as SourceProductCard
} from 'SourceComponent/ProductCard/ProductCard.component';

import './ProductCard.style';

/**
 * Product card
 * @class ProductCard
 * @namespace Scandipwa/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCard {
    renderCardContent() {
        const { renderContent, product: { name, items = {} }, isOpened} = this.props;       

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            <div block="ProductCard" elem="LinkInnerWrapper" mods={ { loaded: !!name } }>
                <div block="ProductCard" elem="Header">
                    { this.renderProductCardWishlistButton() }
                    <ProductLabelsComponent product={this.props.product} />
                </div>
                {this.renderCardLinkWrapper((
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                    </div>
                ))}
                <div block="ProductCard" elem="Content" mods={{showAllGroupedItems: isOpened}}>
                    {this.renderCardLinkWrapper((
                        this.renderName(false)
                    ))}
                    { this.displaySamplerShortDescription() }
                    <div block="ProductCard" elem="GroupedItems">
                        {this.props.product.type_id == "configurable" ? 
                            <GroupedProductsItem
                                key={ this.props.product.id }
                                product={ this.props.product }
                                defaultQuantity={ this.props.minQuantity }
                                quantity={ this.props.quantity }
                                setQuantity={ this.props.setQuantity }
                                showImages={false}
                            />                     
                        :
                        <GroupedProductList
                            product={ this.props.product }
                            quantity={ this.props.quantity }
                            setQuantity={ this.props.setQuantity }
                            showImages={false}
                        />
                        }
                        { this.renderCollapsibleToggle() }
                    </div>
                </div>
            </div>
        );
    }

    renderCollapsibleToggle () {
        const { product: { items = [] }, toggleOpended, isLoading, productName } = this.props;  

        if (items.length <= 2) {
            return null;
        }

        return (
            <span block="ProductCard" elem="ShowMore" onClick={toggleOpended}>
                {!productName ?
                <>
                    <TextPlaceholder content={ productName } length="short" />
                </>
                :
                <>
                    <ChevronIcon direction={ RIGHT } />
                    {__('Show more variants')}
                </>
                }

            </span>
        );
    }

    displaySamplerShortDescription() {
        const {
            product: {
                short_description = {}
            }
        } = this.props;

        if(!short_description.html) {
            return null;
        }

        return (
            <p block="ProductCard" elem="Description">
                { short_description.html }
            </p>
        );
    }

    renderCardListContent() {
        const {
            children, 
            layout, 
            renderContent, 
            product: { 
                name,
                attributes: {
                    format = {},
                    length_mm = {},
                    cigar_short = {},
                    coupon_amount
                } = {}
            }
        } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            <div block="ProductCard" elem="Link" mods={ { isShortCigar: (cigar_short.attribute_value === "1" || !!coupon_amount) } }>
                <div block="ProductCard" elem="Header">
                    { this.renderProductCardWishlistButton() }
                    <ProductLabelsComponent product={this.props.product} />
                </div>
                
                {this.renderCardLinkWrapper((
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure" 
                            mods={{isNotSampler: format.attribute_value !== "96" && format.attribute_value !== "28"}}   
                            style={ { width: `${ length_mm.attribute_value > 0 && cigar_short.attribute_value !== "1" ?  (length_mm.attribute_value / 250) * 100 : '100' }%`} }>
                            { this.renderPictureForList() }
                        </figure>
                        { this.renderScale() }
                    </div>
                ))}
                <div block="ProductCard" elem="Content" mods={ { layout } }>
                {this.renderCardLinkWrapper((
                    this.renderName(false)
                ))}
                    {this.props.product.type_id == "configurable" ? 
                        <GroupedProductsItem
                            key={ this.props.product.id }
                            product={ this.props.product }
                            defaultQuantity={ this.props.minQuantity }
                            quantity={ this.props.quantity }
                            setQuantity={ this.props.setQuantity }
                            showImages={false}
                        />                     
                    :
                    <GroupedProductList
                        product={ this.props.product }
                        quantity={ this.props.quantity }
                        setQuantity={ this.props.setQuantity }
                        showImages={false}
                    />
                    }
                </div>
            </div>
        );
    }

    renderPictureForList(mix = {}) {
        const { product: { id, name }, list_thumbnail } = this.props;

        return (
            <>
                <img
                  alt={ name }
                  src={ list_thumbnail }
                />
            </>
        );
    }

    renderScale() {
        const {
            product: {
                categories = [],
                attributes: {
                    length_mm = {},
                    format = {},
                    cigar_short = {}
                } = {}
            }
        } = this.props;

        const isCigar = length_mm.attribute_value ? length_mm.attribute_value : "";

        if (!isCigar) {
            return null;
        }

        if (format.attribute_value === "28" || cigar_short.attribute_value === "1") {
            return null
        }

        return (
            <div block="ProductCard" elem="Scale">
            <span className="measure">{ __('cm') }</span>
            <div block="Scale" elem="Wrapper">
                <span className="segment">0</span>
                <span className="segment">5</span>
                <span className="segment">10</span>
                <span className="segment">15</span>
                <span className="segment">20</span>
            </div>
        </div>
        );
    }

    render() {
        const {
            children,
            mix,
            isLoading,
            layout
        } = this.props;

        if (layout === LIST_LAYOUT) {
            return (
                <li
                  block="ProductCard"
                  mods={ { layout } }
                  mix={ mix }
                >
                    <Loader isLoading={ isLoading } />
                    { this.renderCardListContent() }
                </li>
            );
        }

        return (
            <li
              block="ProductCard"
              mods={ { layout } }
              mix={ mix }
            >
                <Loader isLoading={ isLoading } />
                { this.renderCardContent() }
                <div block="ProductCard" elem="AdditionalContent">
                    { children }
                </div>
            </li>
        );
    }
}

export default ProductCardComponent;
